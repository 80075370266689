const products = {
  titleAdd: 'Create Product',
  titleEdit: 'Edit Product',
  name: 'Product name',
  description: "Product Description",
  price: "Price",
  word_amount: "Word amount",
  short_description: "Short Description",
  sale_price: "Sale Price",
  is_subscription: 'Is subscription?',
  expiration_days: 'Expiration Days',
  subscription_period: 'Subscription Period',
  subscription_interval: 'Subscription Interval',
  limit_per_user: 'Limit per User',
  free_trial_period: 'Free Trial Period',
  free_trial_words: 'Free Trial Words Amount',
  user_limit: 'Purchases Limit',
  stripe_product_id: 'Stripe Product Id',
  available: 'Available',
  day: 'Day(s)',
  year: 'Year(s)',
  month: 'Month(s)',
}

export default products;