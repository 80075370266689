import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";

const ProductRow = ({ product, extraFields }) => {
  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td>{product[field.key]}</td>);
    }
  };

  return (
    <tr className="p-2 border-bottom small align-middle hover-light">
      <td className="td-id">{product.product_id}</td>
      <td>
        <Link to={`${product.product_id}/edit`}>
          <i className="fa fa-eye me-2"></i>
          {product.name}
        </Link>
      </td>
      <td>
        {product.word_amount}
      </td>
      <td>
        {formatMonto(product.price)}
      </td>
     
      {renderExtraFields()}
    </tr>
  );
};

export default ProductRow;
