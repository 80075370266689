const avatar = {
  files: "Archivos",
  editable_files: "Archivos Editables",
  files_empty: "Aún no has cargado archivos a este avatar",
  editable_files_empty: "Aún no has cargado archivos de texto editables a este avatar",
  files_description: "Mejora las respuestas de tu avatar con archivos de referencia (PDFs)",
  editable_files_description: "Mejora las respuestas de tu avatar con archivos editables de referencia (en formato TXT)",
  context_header: "Contexto",
  context_description: "Entrena tu Avatar dándole un contexto de quién es",
  train_start: "Comienza a entrenarlo",
  context_empty: "no tiene contexto aún",
  train_title: "Entrena tu Avatar",
  search: "Buscar contenido...",
  select: "Seleccionar",
  train: "Entrenar",
  import: "Importar",
  content: "Contenido",
  combine: "Combinar",
  details: "Detalles",
  selecting: "Seleccionando",
  trainings: {
    execute: "Entrenar",
    start:
      "Esto iniciará un nuevo entrenamiento con este conjunto de datos y se hará un cargo a tu cuenta de OpenAI entre $20 y $100 USD dependiendo del tamaño del conjunto de datos.",
    search: "Buscar entrenamientos...",
    title: "Entrenamientos",
    single: "Entrenamiento",
    sample_file: "Plantilla",
    empty: "You don't have any trainings.",
    card: {
      last_update: "Última Actualización",
    },
  },
  outputs: {
    details: "Detalles",
    title: "Resultados",
    card: {
      details: "Mostrar detalles",
      delete: "Eliminar Resultado",
      edit: "Editar Resultado",
    },
  },
};

export default avatar;
