const products = {
  titleAdd: 'Crear Producto',
  titleEdit: 'Editar Producto',
  name: 'Nombre del Producto',
  description: "Descripción del Producto",
  price: "Precio",
  word_amount: "Cantidad de Palabras",
  short_description: "Descripción Corta",
  sale_price: "Precio de oferta",
  is_subscription: '¿Es cargo recurrente?',
  expiration_days: 'Dias de Expiración',
  subscription_period: 'Periodo de cargos',
  limit_per_user: 'Limite por usuario',
  free_trial_period: 'Periodo de prueba gratis',
  user_limit: 'Limite de Compras',
  stripe_product_id: 'ID de producto de Stripe',
  available: 'Disponible',
}

export default products;