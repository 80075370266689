const avatars = {
  title: "Avatars",
  saved: "Avatar saved.",
  add_context: 'Avatar Context',
  select_context: 'Select an avatar to add context',
  search: "Search avatars...",
  deleted: "Avatar deleted.",
  synced: "Avatar synced. It may take up to 15 minutes to train a avatar.",
  combined: "Avatar combined. It may take up to 15 minutes to train a avatar.",
  delete_confirm: "Are you sure you want to delete this avatar",
  empty: "Aún no tienes avatares",
  upgrade:
    "Crear un avatar que piensa, escribe y suena como tu (o hasta mejor que tú) es una function de pago de Bemodo IA.",
};

export default avatars;
