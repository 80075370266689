import { Link, navigate } from "@reach/router";
import React, { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import useTranslations from "../../hooks/useTranslations";
import { ProductsContext } from "../../context/ProductsContext";
import Switch from "react-switch";
import { getValue } from "../../utils";
import moment from "moment";

const ProductForm = ({ product_id }) => {
  const [freeTrialProp, setFreeTrialProp] = useState('free_trial_length');
  const translations = useTranslations();

  const {
    spinner,
    product,
    getSingleProduct,
    setProduct,
    saveProduct,
    createProduct,
    setProductProperty,
  } = useContext(ProductsContext);

  useEffect(() => {
    if (isNaN(product_id)) {
      createProduct();
    } else {
      getSingleProduct(product_id);
    }

    return () => {
      setProduct(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveProduct(product, () => {
      navigate('/admin/products');
    });
   
    // createUserNoSignUp(customer)
    //   .then(() => {
    //     if(document.location.pathname.includes('new')) {
    //       setCustomer(null);
    //       navigate('/admin/users');
    //     }
    //   });
  };


  const addDefaultPeriod = () => {
    setProductProperty("subscription_period", "month");
    setProductProperty("subscription_interval", "1");
  };

  const renderSubscriptionOpts = () => {
    if (getValue(product, "is_subscription", "boolean")) {
      return (
        <div className="row align-items-center mb-2">
          <label className="px-0">{translations.products.subscription_interval}</label>
          <div className="ps-0 col-12 col-md-2 mb-3">
            <input
              type="number"
              className="form-control"
              value={product.subscription_interval}
              onChange={(e) => setProductProperty("subscription_interval", e.target.value)}
            />
          </div>
          <div className="col-12 col-md-10 mb-3">
            <select
              className="form-control"
              value={product.subscription_period}
              onChange={(e) => setProductProperty("subscription_period", e.target.value)}
            >
              <option value="day">{translations.products.day}</option>
              <option value="month">{translations.products.month}</option>
              <option value="year">{translations.products.year}</option>
            </select>
          </div>
      </div>
      );
    }
  }

  const renderFreeTrialOpts = () => {
    const free_trial_value = freeTrialProp === "free_trial_end"
      ? moment(product.free_trial_end).utc().format("YYYY-MM-DD")
      : product.free_trial_length;

    if (getValue(product, "is_subscription", "boolean")) {
      return (
        <div className="row align-items-center mb-2">
          <label className="px-0">{translations.products.free_trial_period}</label>
          <div className="col-12 col-md-2 mb-3 ps-0">
            <select
              value={freeTrialProp}
              className="form-control"
              onChange={(e) => setFreeTrialProp(e.target.value)}
            >
              <option value="free_trial_length">Days</option>
              <option value="free_trial_end">Date</option>
            </select>
          </div>
          <div className="col-12 col-md-10 mb-3">
            <input
              type={freeTrialProp === "free_trial_length" ? "number" : "date"}
              className="form-control"
              placeholder="Leave unfulfilled to disable"
              value={free_trial_value !== null ? free_trial_value : ""}
              onChange={(e) => setProductProperty(freeTrialProp, e.target.value)}
            />
          </div>
      </div>
      );
    }
  }

  const renderForm = () => {
    if (product && product !== null) {
      return (
        <form onSubmit={handleSubmit}>
          <label>{translations.products.name}</label>
          <input
            type="text"
            className="form-control mb-3"
            value={product.name}
            onChange={(e) => setProductProperty("name", e.target.value)}
          />
          <label>{translations.products.description}</label>
          <textarea
            className="form-control mb-3"
            value={product.description}
            onChange={(e) => setProductProperty("description", e.target.value)}
          />
          <label>{translations.products.short_description}</label>
          <input
            type="text"
            className="form-control mb-3"
            value={product.short_description}
            onChange={(e) => setProductProperty("short_description", e.target.value)}
          />

          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.price}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.price}
                onChange={(e) => setProductProperty("price", e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.sale_price}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.sale_price}
                onChange={(e) => setProductProperty("sale_price", e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.word_amount}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.word_amount}
                onChange={(e) => setProductProperty("word_amount", e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.free_trial_words}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.free_trial_words}
                onChange={(e) => setProductProperty("free_trial_words", e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.stripe_product_id}</label>
              <input
                type="text"
                className="form-control mb-3"
                value={product.stripe_product_id}
                onChange={(e) => setProductProperty("stripe_product_id", e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
            <label>Stripe Price Id</label>
              <input
                type="text"
                className="form-control mb-3"
                value={product.stripe_price_id}
                onChange={(e) => setProductProperty("stripe_price_id", e.target.value)}
              />
            </div>
          </div>

          <h4 className="border-bottom pb-2">Subscription</h4>
          <div className="row mb-2">
            <div className="col-6 ps-0">
              <label>{translations.products.is_subscription}</label>
            </div>
            <div className="col-6">
              <Switch
                checked={getValue(
                  product,
                  "is_subscription",
                  "boolean"
                )}
                onChange={(checked) => {
                  setProductProperty("is_subscription", checked);
                  if (checked) addDefaultPeriod();
                }}
              />
            </div>
          </div>
          {renderSubscriptionOpts()}
          {renderFreeTrialOpts()}

          <h4 className="border-bottom pb-2">Limits</h4>

          <div className="row">
            <div className="col-6 ps-0">
              <label>{translations.products.user_limit}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.user_limit}
                onChange={(e) => setProductProperty("user_limit", e.target.value)}
              />
            </div>
            <div className="col-6 pe-0">
              <label>{translations.products.limit_per_user}</label>
              <input
                type="number"
                className="form-control mb-3"
                value={product.limit_per_user}
                onChange={(e) => setProductProperty("limit_per_user", e.target.value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <button className="btn btn-primary">
                {spinner ? <div className="spinner-border"></div> : translations.admin.users.saveBtn}
              </button>
            </div>
            <div className="col-6 text-right">
              <Link
                to="/myadmin/customers"
                className="btn btn-link text-secondary"
              >
                {translations.admin.users.cancelBtn}
              </Link>
            </div>
          </div>
        </form>
      );
    }
    return <div className="spinner-border"></div>;
  };

  return <div>{renderForm()}</div>;
};

export default ProductForm;
