import React, { useReducer, createContext } from "react";
import ProductsReducer from "../reducers/ProductsReducer";
import { PRODUCTS_RECEIVED, SET_PRODUCT, SET_PRODUCT_PROPERTY } from "../types/products";
import ProductsService from "../services/ProductsService";

const initialState = {
  spinner: false,
  products: null,
  product: null,
};

const schema = {
  product_id: "nuevo",
  name: "",
  description: "",
  price: 0,
  word_amount: 0,
};

export const ProductsContext = createContext(initialState);

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsReducer, initialState);

  const getPAvailableProducts = (filters) => {
    ProductsService.getAvailableProducts(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const getAllProducts = (filters) => {
    ProductsService.getAllProducts(filters).then((res) => {
      const { products } = res.data;
      dispatch({ type: PRODUCTS_RECEIVED, payload: products });
    });
  };

  const createProduct = () => {
    setProduct(schema);
  }

  const setProduct = (product) => {
    dispatch({ type: SET_PRODUCT, payload: product });
  };

  const clearProduct = () => {
    dispatch({ type: SET_PRODUCT, payload: null });
  };

  const clearProducts = () => {
    dispatch({ type: PRODUCTS_RECEIVED, payload: null });
  };

  const setProductProperty = (key, value) => {
    dispatch({ type: SET_PRODUCT_PROPERTY, payload: { key, value } });
  };

  const getSingleProduct = (product_id) => {
    ProductsService.getSingleProduct(product_id).then((res) => {
      const { product } = res.data;
      dispatch({ type: SET_PRODUCT, payload: product });
    });
  };

  const saveProduct = (data, callback) => {
    let service;
    if (isNaN(parseInt(data?.product_id))) {
      service = ProductsService.postProduct;
    } else {
      service = ProductsService.putProduct;
    }
    
    return service(data)
      .then(res => {
        const { product } = res.data;
        setProduct(product);
        if(typeof callback === 'function') callback();
        return product;
      })
      .catch(alert);
  }

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        setProduct,
        getPAvailableProducts,
        getAllProducts,
        clearProduct,
        saveProduct,
        clearProducts,
        createProduct,
        getSingleProduct,
        setProductProperty
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
