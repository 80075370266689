import { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import { useUploadFiles } from "../global/useUploadFiles";

const generalConfig = [
  'title',
  'tagline',
  'high_ticket_offer_name',
  'high_ticket_offer_link',
  'landing_image_src',
  'landing_logo_src',
  'favicon_url',
  'feature1_title',
  'feature2_title',
  'feature3_title',
  'feature1_description',
  'feature2_description',
  'feature3_description',
  'benefit1_title',
  'benefit2_title',
  'benefit3_title',
  'benefit1_description',
  'benefit2_description',
  'benefit3_description',
  'stripe_public_key',
  'stripe_private_key',
  'stripe_webhook_key',
  'openai_secret_key',
  'openai_model',
  'welcome_email_webhook_url',
  'reset_password_email_webhook_url'
]

export const useCrudAppConfig = () => {
  const appconfig = useContext(AppConfigContext);
  
  const {
    images_inputs,
    setAppConfigProperty,

    updateAppConfig
  } = appconfig;

  const { uploadFiles } = useUploadFiles();

  const saveAppConfig = async () => {
    setAppConfigProperty('loading', true);
    
    const newFiles = await uploadFiles(images_inputs);

    const data = {}

    generalConfig.map(configKey => {
      data[configKey] = appconfig[configKey];
    });

    newFiles.forEach(file => {
      data[file.key] = file.fileName;
    })

    await updateAppConfig(data);  
    setAppConfigProperty('loading', false);
  }

  return { saveAppConfig };
}